import React from 'react'
import { Container } from 'reactstrap'

import Disclosure from '../components/Disclosure/Disclosure'
import Layout from '../components/layout/layout'
import { Helmet } from "react-helmet"



const DisclosurePage = () => {
    return (
        <div>
          <Helmet title="DNNae | Disclosure"/>
            <Layout
                containerStyle={{
                  position: 'relative'
                }}
                selectedState='disclosure'
                buttonStatus={false}
                pageTitle='isclosure'
                contactSalesLink='/#contactSalesPanel'
            >
              <Container

                style={{
                  maxWidth: '1920px'
                }}
              >
                  <Disclosure
                  
                    heading="Disclosure"
                  
                  />
              </Container>
            </Layout>       
            {/* </Helmet> */}
        </div>
    )
}

export default DisclosurePage
