import React from 'react'
import { disclosureContent } from './disclosureContent'

import './Disclosure.scss'

const Disclosure = ({
    heading='',
}) => {
    return (
        <div
            className='disclosureContent'
        >
            { heading && <h1>{heading}</h1>}
            <div
                className='disclosureContentContainer'
            >
                {
                    disclosureContent.map(item => {
                        return(
                            <span>
                                {item.headline && <h2>{item.headline}</h2>}
                                {
                                    item.content.map(description => <p dangerouslySetInnerHTML={{__html: description}}/>)
                                }
                            </span>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Disclosure
