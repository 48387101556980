export const disclosureContent = [
    {
        headline: "",

        content: [
            `To automate conversations initiated by a user, DNNae app uses the Gmail API to send and read emails through a user's Gmail account on their behalf.`,

            `The DNNae app's use of information received from Google APIs adheres to the <a href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</a>, including the Limited Use requirements.`,

            `For more information, please contact <a href="mailto:admin@dnnae".com>admin@dnnae.com</a> with any questions.`,
        ],

        index: 1,
    },
]